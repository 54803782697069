.infoBox {
    overflow-x: hidden !important;
}
.marker-infobox--container {
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--grey-32);
    border-radius: 5px;
    padding: 5px;
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    width: max-content;
    overflow-x: hidden;
}
.marker-infobox--container p {
    margin: 5px;
    text-transform: capitalize;
    overflow-x: hidden;
}
.marker-infobox--container button {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

.filter-container {
    position: relative;
    top: 4rem;
    left: 0.6rem;
    padding: 1rem;
    font-size: 0.8rem;
    background-color: #fff;
    max-width: 15rem;
}
.filter-container .filter-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.filter-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}
.filter-container .form-group select,
.filter-container .form-group input {
    padding: 0.4rem;
}
.filter-container .form-group select,
.filter-container .form-group option {
    cursor: pointer;
}
